<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Reporte de limpieza</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn v-if="records.length > 0" color="accent" @click="clickPrint()" class="mr-1">
					<v-icon left small>mdi-file-pdf</v-icon> Imprimir
				</v-btn>
				<v-btn v-if="records.length > 0" color="accent" @click="clickDownloadExcel()">
					<v-icon left small>mdi-file-excel</v-icon> Exportar
				</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row dense>
				<v-col cols="12" xl="2" lg="2" md="2" sm="2">
					<div class="form-group">
						<label class="control-label">Fecha</label>
						<el-date-picker
							v-model="search.start"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:picker-options="pickerOptions"
							:clearable="false"
							:editable="false"
						></el-date-picker>
					</div>
				</v-col>
				<v-col cols="12" xl="2" lg="4" md="12" sm="12">
					<el-button
						type="primary"
						icon="el-icon-search"
						class="btn-block mt-5"
						:loading="loading"
						@click="getData"
					></el-button>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="12" lg="12">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<td class="text-right">N°</td>
									<td class="text-center">HABITACIÓN</td>
									<td class="text-center">P</td>
									<td class="text-center">J</td>
									<td class="text-center">T</td>
									<td class="text-center">RESPONSABLE</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in records" :key="index">
									<td class="text-right">
										{{ index + 1 }}
									</td>
									<td class="text-center">
										{{ row.room }}
									</td>
									<td class="text-center">
										{{ row.P }}
									</td>
									<td class="text-center">
										{{ row.J }}
									</td>
									<td class="text-center">
										{{ row.T }}
									</td>
									<td class="text-center">
										{{ row.personnel }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import printJS from 'print-js';
export default {
	data() {
		return {
			resource: 'reports/cleaning',
			records: [],
			loading: false,
			search: {
				start: dayjs().format('YYYY-MM-DD'),
			},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	methods: {
		async getData() {
			this.loading = true;
			await this.$http.post(`/${this.resource}/records`, this.search).then((response) => {
				this.records = response.data.records;

				this.loading = false;
			});
		},
		clickDownloadExcel() {
			this.$http({
				url: `/${this.resource}/excel`,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');

				fileLink.href = fileURL;
				fileLink.setAttribute('download', `ReporteLimpieza-${new Date().getTime()}.xlsx`);
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},
		clickPrint() {
            this.$http({
                url: `/${this.resource}/pdf`,
                method: "get",
                responseType: "blob",
                params: this.search
            }).then(response => {
				var fileURL = window.URL.createObjectURL(
					new Blob([response.data])
                );
				
				printJS(fileURL);
            });
		}
	},
};
</script>
